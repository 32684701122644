import { ApiOptions } from "~/old-app/types/api";
import {
  useLazyServiceQuery,
  useServiceQuery,
  useServicesQuery,
} from "./service-api";
import { useConfigSlice } from "~/old-app/redux/config-slice";
import { useEffect, useState } from "react";
import { Service } from "./service-types";

// export const useBranches = (service?: string) => {
//   const { data, isLoading, isFetching } = useBranchesQuery(service, {
//     skip: !service,
//   });
//   return {
//     branches: data?.response.data || [],
//     isLoading,
//     isFetching,
//     total_pages: data?.response.meta?.total || 0,
//   };
// };

export const useService = (with_blocks: number = 0, _page = 1) => {
  const { selectedService } = useConfigSlice();
  const [blocks, setBlocks] = useState<Service["blocks"]>([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  // const { data, isLoading, isFetching } = useServiceQuery(
  const [getService, { data, isLoading, isFetching }] = useLazyServiceQuery();
  // {
  //   id: selectedService,
  //   paginate_blocks: 1,
  //   page,
  //   per_page: 4,
  //   with_blocks,
  // },
  // {
  //   skip: !selectedService,
  // }

  const getServicePages = async () => {
    if (!hasMore) return;
    getService({
      id: selectedService,
      paginate_blocks: 1,
      page,
      per_page: 4,
      with_blocks,
    })
      .unwrap()
      .then((data) => {
        if (data.response.data?.blocks?.length) {
          setBlocks((b) => [
            ...(b || []),
            ...(data.response.data.blocks || []),
          ]);
          setPage(page + 1);
        } else {
          setHasMore(false);
        }
      });
  };

  useEffect(() => {
    if (selectedService && hasMore) {
      getServicePages();
    }
  }, [selectedService, page, hasMore]);

  return {
    service: data?.response.data,
    blocks,
    isLoading,
    isFetching,
  };
};

export const useServices = (options?: ApiOptions) => {
  const { data, isLoading, isFetching } = useServicesQuery(undefined, options);
  return {
    services: data?.response.data || [],
    isLoading,
    isFetching,
  };
};
