import { dispatch } from "~/old-app/redux";
import { ConfigActions } from "~/old-app/redux/config-slice";
import { Response } from "../../types/api";
import { Api } from "../api-store";
import { Service } from "./service-types";

export const ServiceApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    services: builder.query<Response<Service[]>, void>({
      providesTags: ["services"],
      query: () => ({
        url: `/services`,
        method: "GET",
      }),
      transformResponse: (response: Response<Service[]>) => {
        dispatch(ConfigActions.setServices(response.response.data || []));

        return response;
      },
    }),
    service: builder.query<
      Response<Service>,
      {
        id?: number;
        paginate_blocks?: number;
        page?: number;
        per_page?: number;
        with_blocks?: number;
      }
    >({
      providesTags: ["service"],
      query: ({ id, ...params }) => ({
        url: `/services/${id}`,
        method: "GET",
        params,
      }),
      transformResponse: (response: Response<Service>) => {
        dispatch(ConfigActions.setService(response.response.data));

        return response;
      },

      // serializeQueryArgs: ({ endpointName, queryArgs }) => {
      //   const { page, ...queryParams } = queryArgs as any;

      //   return {
      //     endpointName,
      //     ...queryParams,
      //   };
      // },
      // merge: (currentCache: Response<Service>, newItems: Response<Service>) => {
      //   currentCache.response.data?.blocks?.push(
      //     ...(newItems.response.data?.blocks || [])
      //   );
      //   return currentCache as Response<Service>;
      // },

      // forceRefetch({ currentArg = {}, previousArg = {} }) {
      //   return Object.keys(currentArg).some(
      //     (key) => (currentArg as any)[key] !== (previousArg as any)[key]
      //   );
      // },
    }),
  }),
});

export const { useServiceQuery, useLazyServiceQuery, useServicesQuery } =
  ServiceApi;
